.App {
  text-align: center;
  width: 375px;
  background: #1E1E1F;
  padding: 20px 16px 40px;
}

.input-text {
  background: #3F3F40 !important;
  border: 1px solid #DADADB;
  color: white !important;
}

.input-text:focus {
  background: white !important;
  color: black !important;
}

.input-text-error {
  border: 1px solid red !important;
}

.carousel {
  touch-action: pan-y;
}

.requirement {
  font-family: Nunito Sans, sans-serif;
  font-size: 18px;
  font-weight: 600;
  line-height: 22px;
  text-align: left;
  color: white;

  h1 {
    margin-top: 32px;
    font-family: Nunito Sans, sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    color: #BFBFC0;
  }
}

.arrow-wrapper {
  display: flex;
  width: 56px;
  height: 56px;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  box-shadow: 0 0 30px 0 rgba(0, 0, 0, 1.4)
}

.map {
  margin-top: -20px;
  margin-left: -16px;

  img {
    width: 375px;
    height: 200px;
    object-fit: cover;
  }
}

.call-renew {
  color: #124BF6 !important;
  cursor: pointer;
  width: fit-content !important;
}

.btn {
  width: 303px !important;
}

.dot {
  width: 8px !important;
  height: 8px !important;
  border-radius: 50% !important;
  margin-right: 5px !important;
  margin-left: 5px !important;
  box-shadow: none!important;
}

.carousel {
  height: 200px !important;
  width: 375px !important;
  margin-left: -16px !important;
  margin-top: -20px !important;
}

.btn-primary {
  background: #336BEB !important;
  height: 40px;
  display: flex !important;
  align-items: center;
  justify-content: center;
  font-family: Nunito Sans, sans-serif!important;
  font-size: 14px!important;
  font-weight: 700!important;
  line-height: 18px!important;
}

.btn-secondary {
  background: #3F3F40 !important;
  height: 40px;
  display: flex;
  align-items: center;
  font-family: Nunito Sans, sans-serif!important;
  font-size: 14px!important;
  font-weight: 700!important;
  line-height: 18px!important;
  text-align: left;
  border: 0!important;
}

.btn-outline-dark {
  background: #F2F2F2 !important;
  border: 0 !important;
  height: 40px;
  font-family: Nunito Sans, sans-serif!important;
  font-size: 14px!important;
  font-weight: 700!important;
  line-height: 18px!important;
  text-align: left;
  color: #1E1E1F!important;

  &:hover {
    color: black!important;
  }
}

.text {
  font-family: Nunito Sans, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  text-align: left;
  color: #BFBFC0;
}

.text-white {
  font-family: Nunito Sans, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  text-align: left;
  color: white;

  &::-webkit-scrollbar {
    width: 2px;
  }

  &::-webkit-scrollbar-thumb {
    background: #BFBFC0;
  }

  &::-webkit-scrollbar-track {
    background: #F2F2F2;
  }
}

.overflow-no-scroll {
  &::-webkit-scrollbar {
    height: 0;
  }

  &::-webkit-scrollbar-thumb {
    width: 0;
    background: #BFBFC0;
  }

  &::-webkit-scrollbar-track {
    background: #1E1E1F;
    width: 8px;
  }
}

.carousel-root {
  user-select: none!important;
}

.iframe-document {

  &::-webkit-scrollbar {
    width: 2px !important;
  }

  &::-webkit-scrollbar-thumb {
    background: #BFBFC0 !important;
  }

  &::-webkit-scrollbar-track {
    background: #F2F2F2 !important;
  }
}

.expand {
  font-family: Nunito Sans, sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.20000000298023224px;
  text-align: left;
  color: #336BEB;
}

.cursor-pointer {
  cursor: pointer;
}

.line {
  height: 1px;
  background: #484849;
  width: 375px !important;
  margin-left: -16px;
}

.address-label {
  font-family: Nunito Sans, sans-serif;
  font-size: 18px;
  font-weight: 800;
  line-height: 22px;
  text-align: left;
  color: white;
}

.title {
  font-family: Mulish, sans-serif;
  font-size: 16px;
  font-weight: 900;
  line-height: 20.08px;
  text-align: left;
  color: white;
}

.date {
  font-family: Nunito Sans, sans-serif;
  font-size: 18px;
  font-weight: 800;
  line-height: 22px;
  text-align: left;
  color: white;
}

.adLogo {
  height: 50%;
  width: 100%;
  object-fit: contain;
}

.wrapper {
  border-radius: 12px;
  padding: 20px;
  background: #303031;
  min-width: 343px;
}

.wrapper-gradient {
  border-radius: 12px;
  padding: 20px;
  margin-top: 40px;
  width: 343px;
  background: radial-gradient(100% 283.19% at 0% 0%,
          rgba(111, 147, 255, 0.7) 0%,
          rgba(73, 215, 189, 0.6) 52.3%,
          rgba(55, 55, 57, 0.9) 100%
  )
}

.mt-40 {
  margin-top: 40px;
}

.avatar {
  width: 48px;
  height: 48px;
  border-radius: 50%;
}

.form-check-input {
  width: 1.5em !important;
  height: 1.5em !important;
}

.label {
  font-family: Nunito Sans, sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
  color: #BFBFC0;
  width: 100%;
}

.label-white {
  font-family: Nunito Sans, sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
  color: white;
  width: 100%;
}

.subtitle {
  font-family: Nunito Sans, sans-serif;
  font-size: 18px;
  font-weight: 800;
  line-height: 22px;
  text-align: left;
  color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.enter-phone {
  font-family: Nunito Sans, sans-serif;
  font-size: 24px;
  font-weight: 500;
  line-height: 28.8px;
  text-align: left;
  color: white;
}

.enter-phone-text {
  font-family: Nunito Sans, sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 19.2px;
  text-align: left;
  color: white;
}

.progressStep {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 225px;
}

.text-large {
  font-family: Nunito Sans, sans-serif;
  font-size: 30px;
  font-weight: 800;
  line-height: 36px;
  text-align: left;
  color: white;
}

.object-card {
  border-radius: 12px;
  background: #303031;
  position: relative;

  img {
    border-radius: 12px 12px 0 0;
    object-fit: cover;
  }
}

.object-card-preview-vote {
  position: absolute;
  right: 10px;
  bottom: 107px;
  display: flex;
  justify-content: end;

  img {
    height: 10%;
    width: 25%;
  }
}

.footerLogo {
  margin-left: -16px;
  margin-bottom: -40px;
}

.vote-selected {
  svg {
    path {
      fill: #336BEB;
    }
  }
}

.vote-not-selected {
  svg {
    path {
      fill: white;
    }
  }
}